import React, { useContext, useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { DadosContext } from '../common/DadosContext';
import { CircularProgress, Button } from '@mui/material';
import DateFilter from './DateFilter';
import { agruparVendasPorData, detectarAnomalias, previsaoVendasTensorFlow } from './utils/analytics';
import SalesChart from './SalesChart';
import PredictionsChart from './PredictionsChart';
import AnomaliesChart from './AnomaliesChart';
import MovingAverageChart from './MovingAverageChart';

import TopProductsCard from './TopProductsCard';

// Componente de Card Informativo
const InfoCard = ({ title, value }) => (
    <div className="bg-zinc-900 shadow-md rounded-lg p-4 text-center">
        <h3 className="text-lg font-bold text-gray-700">{title}</h3>
        <p className="text-2xl text-blue-500 font-semibold">{value}</p>
    </div>
);

const TopProductsList = ({ products }) => (
    <div className="bg-gray-100 rounded-lg shadow-md p-4 mt-6">
        <h3 className="text-lg font-bold mb-4">Top 20 Produtos Mais Vendidos</h3>
        <ul className="list-disc pl-6">
            {products.map((product, index) => (
                <li key={product.sku} className="mb-2">
                    <strong>{index + 1}.</strong> {product.sku} - {product.quantity} vendas (R$ {product.total.toFixed(2)})
                </li>
            ))}
        </ul>
    </div>
);


const PedidoMagalu = () => {
    const { tokens } = useContext(DadosContext);
    const [userData, setUserData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [previsoes, setPrevisoes] = useState([]);
    const [mediaMovel, setMediaMovel] = useState([]);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [startDate, setStartDate] = useState(dayjs().startOf('year').toDate());
    const [endDate, setEndDate] = useState(dayjs().endOf('month').toDate());
    const [topProducts, setTopProducts] = useState({
        topMaisVendidos: [],
        topMenosVendidos: [],
        topTendencias: [],
    });

    const fetchFilteredOrders = async () => {
        setLoading(true);
        setError(null);
        const token = tokens.find((t) => t.id === 'magalu')?.token;

        if (!token) {
            setError('Token não encontrado');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.post(
                'https://nerp.com.br/magalu/pedidos',
                {
                    accessToken: token,
                    startDate: dayjs(startDate).format('YYYY-MM-DD'),
                    endDate: dayjs(endDate).format('YYYY-MM-DD'),
                },
                { headers: { 'Content-Type': 'application/json' } }
            );


            const orders = response.data.map((order) => ({
                id: order.id,
                createdAt: order.created_at,
                purchasedAt: order.purchased_at,
                total: order.agreement?.total?.total / 100 || 0,
                sku: order.agreement?.items.map((item) => ({                    
                    sku: item.sku?.code || 'SKU desconhecido', // Garantindo que sempre tenha um SKU
                }))
            }));

            setUserData(orders);
            setTopProducts(response.data);

        } catch (error) {
            setError('Erro ao buscar pedidos: ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    const calcularTopProdutos = (data) => {
        const vendasPorProduto = data.reduce((acc, order) => {
            if (order.sku && Array.isArray(order.sku)) {
                order.sku.forEach((item) => {
                    const sku = item.sku || 'SKU desconhecido';
                    const total = order.total || 0;
    
                    if (!acc[sku]) {
                        acc[sku] = { sku, quantidade: 0, total: 0 };
                    }
    
                    acc[sku].quantidade += 1; // Cada item é contado como uma venda
                    acc[sku].total += total; // Incrementa o total do pedido
                });
            }
            return acc;
        }, {});
    
        const produtosOrdenados = Object.values(vendasPorProduto)
            .sort((a, b) => b.quantidade - a.quantidade);
    
        return {
            topMaisVendidos: produtosOrdenados.slice(0, 20),
            topMenosVendidos: produtosOrdenados.slice(-20).reverse(),
            topTendencias: produtosOrdenados.slice(0, 20), // Personalize conforme sua lógica
        };
    };
    

    useEffect(() => {
        if (userData.length) {
            const topProdutosCalculados = calcularTopProdutos(userData);
            setTopProducts(topProdutosCalculados);
        }
    }, [userData]);

    const processarDadosProdutos = (dados) => {
        return dados.reduce((acc, order) => {
            // Cada pedido pode ter múltiplos itens (SKUs)
            if (order.sku && Array.isArray(order.sku)) {
                order.sku.forEach((item) => {
                    const sku = item.sku || 'SKU desconhecido';
                    if (!acc[sku]) {
                        acc[sku] = [];
                    }
                    acc[sku].push({
                        total: order.total || 0,
                        date: order.createdAt || order.purchasedAt || '',
                        quantity: 1, // Cada item conta como 1 unidade
                    });
                });
            }
            return acc;
        }, {});
    };
    

    const calcularMediaMovel = (dados, tamanhoJanela = 7) => {
        if (dados.length < tamanhoJanela) return [];
        const mediaMovel = [];

        for (let i = 0; i <= dados.length - tamanhoJanela; i++) {
            const janela = dados.slice(i, i + tamanhoJanela).map(item => item.total);
            const media = janela.reduce((acc, val) => acc + val, 0) / tamanhoJanela;
            mediaMovel.push({
                date: dados[i + tamanhoJanela - 1].date,
                media,
            });
        }

        return mediaMovel;
    };

    useEffect(() => {
        if (userData.length) {
            const dadosAgrupados = agruparVendasPorData(userData);
            const dadosComAnomalias = detectarAnomalias(dadosAgrupados);
            setFilteredData(dadosComAnomalias);
            previsaoVendasTensorFlow(dadosAgrupados, setPrevisoes);

            // Calcular média móvel
            const mediaMovelCalculada = calcularMediaMovel(dadosAgrupados);
            setMediaMovel(mediaMovelCalculada);
        }
    }, [userData]);

    console.log("userData:", userData);
    console.log("top:", topProducts);

    // Cálculos para os cards
    const totalVendas = filteredData.reduce((acc, item) => acc + item.total, 0);
    const mediaVendasDiaria =
        filteredData.length > 0
            ? (filteredData.reduce((acc, item) => acc + item.total, 0) / filteredData.length).toFixed(2)
            : 0;
    const diasComAnomalias = filteredData.filter((item) => item.isAnomalia).length;
    const maiorVenda = filteredData.length > 0
        ? filteredData.reduce((max, item) => (item.total > max.total ? item : max), filteredData[0])
        : null;
    const previsaoTotal = previsoes.reduce((acc, value) => acc + value, 0).toFixed(2);

    const productData = processarDadosProdutos(userData);

    // Total de vendas na data de hoje
    const vendasHoje = filteredData
        .filter((item) => dayjs(item.date).isSame(dayjs(), 'day'))
        .reduce((acc, item) => acc + item.total, 0);

    return (
        <div className="container mx-auto px-4 py-36">
            {loading ? (
                <CircularProgress />
            ) : error ? (
                <div>
                    <p className="text-red-500">{error}</p>
                    <Button variant="contained" color="error" onClick={fetchFilteredOrders}>
                        Tentar Novamente
                    </Button>
                </div>
            ) : (
                <div>
                    <div className="flex items-center gap-4">
                        <DateFilter
                            startDate={startDate}
                            setStartDate={setStartDate}
                            endDate={endDate}
                            setEndDate={setEndDate}
                        />
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={fetchFilteredOrders}
                            disabled={loading}
                        >
                            Buscar Pedidos
                        </Button>
                    </div>

                    {/* Cards de Métricas */}
                    <div className="grid grid-cols-1 md:grid-cols-6 gap-4 mt-6 mb-6">
                        <InfoCard title="Total de Vendas" value={`R$ ${totalVendas.toFixed(2)}`} />
                        <InfoCard title="Média Diária" value={`R$ ${mediaVendasDiaria}`} />
                        <InfoCard title="Dias com Anomalias" value={diasComAnomalias} />
                        <InfoCard
                            title="Maior Venda"
                            value={maiorVenda ? `R$ ${maiorVenda.total.toFixed(2)}` : 'N/A'}
                        />
                        <InfoCard title="Previsão de 30 dias" value={`R$ ${previsaoTotal}`} />
                        <InfoCard title="Vendas Hoje" value={`R$ ${vendasHoje.toFixed(2)}`} />
                    </div>

                    {/* Gráficos */}
                    <SalesChart data={filteredData} />
                    <div className="w-full lg:flex grid-cols-1 md:grid-cols-3">
                        <div className='lg:w-1/3'>Previsão de Venda<PredictionsChart previsoes={previsoes} /></div>
                        <div className='lg:w-1/3'>Anomalias<AnomaliesChart data={filteredData} /></div>
                        <div className='lg:w-1/3'>Média Móvel<MovingAverageChart data={mediaMovel} /></div>
                    </div>

                    <TopProductsCard
                        title="Top Produtos"
                        products={topProducts.topMaisVendidos || []}
                        productData={productData || {}}
                    />


                </div>


            )}

        </div>
    );
};

export default PedidoMagalu;
