import React from 'react';
import {
  LineChart,
  Line,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Brush,
  Area,
} from 'recharts';

const SalesChart = ({ data }) => {
  return (
    <ResponsiveContainer width="100%" height={350}>
      <LineChart data={data}>
        <CartesianGrid stroke="#e0e0e0" strokeDasharray="3 3" />
        <XAxis dataKey="date" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey="total" stroke="#4A90E2" strokeWidth={3} />
        <Area type="monotone" dataKey="total" fill="#4A90E2" fillOpacity={0.2} />
        <Brush dataKey="date" height={30} stroke="#4A90E2" />
      </LineChart>
    </ResponsiveContainer>
  );
};

export default SalesChart;
