import { Fragment, useContext, useEffect, useState } from "react";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import { Login } from "../pages/Login/Login";
import { AuthGoogleContext } from "../common/LoginContext";
import Navbar from "../layout/Navbar";
import Footer from "../layout/Footer";
import { DadosProvider } from "../common/DadosContext";
import { HeroSection } from "../layout/Hero";
import AuthorizationButton from "../components/Integracao/Connect";
import AuthorizationCallback from "../components/Integracao/Callback";
import BuscarNota from "../Controle/GetNota";
import NotasComponent from "../Controle/ControleStats/Estatistica";
import Pedidos from "../Pedidos/Pedidos";
import TrackingForm from "../Log/Jadlog/ConsultaJadlog";
import Ferramentas from "../layout/ferramentas";
import { CriarColetaJadlog } from "../Log/Jadlog/CriarColetaJadlog";
import { SimuladorFrete } from "../Log/SimuladorFrete";
import Integracoes from "../components/Integracao/Integracoes";
import { Success } from "../components/Success";
import MlAuthorizationCallback from "../components/Integracao/MlCallBack";
import { Concorrente } from "../MercadoLivre/Concorrente";
import MagaluAuthorizationCallback from "../components/Integracao/MagaluCallback";
import PedidoMagalu from "../MagazineLuiza/Pedidos";
import InventoryNotificationsList from "../MagazineLuiza/Estoque";
import ProdutoAmericanas from "../Americanas/Produto";
import SKUsList from "../MagazineLuiza/Portifolio";

export const AppRoutes = () => {
  const { signed, user } = useContext(AuthGoogleContext);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const loadStorageData = () => {
      const storageUser = sessionStorage.getItem("@AuthFirebase:user");
      const storageToken = sessionStorage.getItem("@AuthFirebase:token");
      if (storageToken && storageUser) {
        setLoading(false);
      }
    };
    loadStorageData();
  }, []);

  const RequireAuth = ({ children }) => {
    
    return signed || user ? children : <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <Fragment>
        <Navbar />
        <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/" element={<><HeroSection /> <Ferramentas /></>} />

          {/* LOGIN NO BLING */}
          <Route path="/callback" element={<AuthorizationCallback />} />
          <Route path="/mlcallback" element={<MlAuthorizationCallback />} />
          <Route path="/magalucallback" element={<MagaluAuthorizationCallback />} />
          <Route path="/dashboard" element={<AuthorizationButton />} />

          {/* BLING */}
          <Route exact path="/dashboard2" element={<DadosProvider user={user}><BuscarNota /></DadosProvider>} />
          <Route exact path="/pedidos" element={<RequireAuth><Pedidos /></RequireAuth>} />
          <Route exact path="/lista-nota" element={<DadosProvider user={user}><NotasComponent /></DadosProvider>} />

          {/* LOGISTICA */}
          <Route
            path="*"
            element={
              <DadosProvider user={user}>
                <Routes>
                  <Route path="/mercadolivre/concorrente" element={<Concorrente />} /> 
                  <Route path="/americanas/precos" element={<ProdutoAmericanas/>} />
                  <Route path="/magazineluiza/pedidos" element={<PedidoMagalu />} />
                  <Route path="/magazineluiza/produtos" element={<SKUsList />} />
                  <Route path="/dashboard2" element={<BuscarNota />} />
                  <Route path="/pedidos" element={<RequireAuth><Pedidos /></RequireAuth>} />
                  <Route path="/nota/ranking" element={<RequireAuth><NotasComponent /></RequireAuth>} />
                  <Route path="/jadlog" element={<TrackingForm />} />
                  <Route path="/coleta" element={<RequireAuth><CriarColetaJadlog /></RequireAuth>} />
                  <Route path="/integracao" element={<RequireAuth><Integracoes /></RequireAuth>} />
                  <Route path="/simulador" element={<RequireAuth><SimuladorFrete /></RequireAuth>} />
                  <Route path="/nota/entrada" element={<RequireAuth><BuscarNota /></RequireAuth>} />
                </Routes>
              </DadosProvider>
            }
          />
          <Route path="/success" element={<Success />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
      </Fragment>
    </BrowserRouter>
  );
};
