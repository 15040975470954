import React, { useEffect, useState } from 'react';
import { Card, CardContent, Typography, Grid, Divider, Tooltip as MuiTooltip } from '@mui/material';
import { Line } from 'react-chartjs-2';
import dayjs from 'dayjs';
import {
    Chart as ChartJS,
    LineElement,
    PointElement,
    LineController,
    CategoryScale,
    LinearScale,
    Tooltip,
    Legend,
} from 'chart.js';

// Registra os componentes necessários do Chart.js
ChartJS.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip, Legend);

// Função para formatar números
const formatNumber = (value, decimalPlaces = 2) =>
    typeof value === 'number' && !isNaN(value) ? value.toFixed(decimalPlaces) : '0.00';

// Função para agrupar os dados por SKU
const processarDadosProdutos = (dados) => {
    return dados.reduce((acc, order) => {
        if (order.sku && Array.isArray(order.sku)) {
            order.sku.forEach((item) => {
                const sku = item.sku || 'SKU desconhecido';
                if (!acc[sku]) {
                    acc[sku] = [];
                }
                acc[sku].push({
                    total: order.total || 0,
                    date: order.createdAt || order.purchasedAt || '',
                    quantity: 1, // Cada item conta como 1 unidade
                });
            });
        }
        return acc;
    }, {});
};

// Função para calcular os KPIs de um produto
const calcularKPIs = (sku, history) => {
    const totalVendas = history.reduce((acc, item) => acc + item.total, 0); // Soma do total
    const pedidos30Dias = history.filter((item) => dayjs().diff(dayjs(item.date), 'day') <= 30).length;
    const precoMedio = totalVendas / (history.length || 1); // Média dos preços
    return { totalVendas, pedidos30Dias, precoMedio };
};

const TopProductsCard = ({ title, products, productData }) => {
    const [kpiData, setKpiData] = useState({});

    useEffect(() => {
        if (products.length > 0 && productData) {
            const kpis = products.reduce((acc, product) => {
                const history = productData[product.sku] || [];
                acc[product.sku] = calcularKPIs(product.sku, history);
                return acc;
            }, {});

            setKpiData(kpis);
        }
    }, [products, productData]);

    return (
        <Card sx={{ margin: '16px', borderRadius: '16px', overflow: 'hidden' }}>
            <CardContent>
                <Typography variant="h5" align="center" sx={{ marginBottom: '16px', fontWeight: 'bold' }}>
                    {title}
                </Typography>
                <Grid container spacing={2}>
                    {products.map((product, index) => {
                        const history = productData[product.sku] || [];
                        const kpis = kpiData[product.sku] || {};

                        // Dados e opções do gráfico
                        const salesData = {
                            labels: history.map((item) => dayjs(item.date).format('DD/MM/YYYY')),
                            datasets: [
                                {
                                    label: 'Vendas',
                                    data: history.map((item) => item.total),
                                    borderColor: '#42A5F5',
                                    borderWidth: 2,
                                    fill: false,
                                },
                            ],
                        };

                        const options = {
                            responsive: true,
                            plugins: {
                                legend: {
                                    display: false,
                                },
                            },
                            scales: {
                                x: {
                                    type: 'category',
                                    title: {
                                        display: true,
                                        text: 'Data',
                                    },
                                },
                                y: {
                                    beginAtZero: true,
                                    title: {
                                        display: true,
                                        text: 'Vendas (R$)',
                                    },
                                },
                            },
                        };

                        return (
                            <Grid item xs={12} md={6} key={index}>
                                <MuiTooltip title={`Detalhes de ${product.nome}`} arrow>
                                    <Card variant="outlined" sx={{ padding: '16px' }}>
                                        <CardContent>
                                            <Typography variant="h6">{product.nome}</Typography>
                                            <Typography variant="body2" color="textSecondary">
                                                SKU: {product.sku}
                                            </Typography>
                                            <Divider sx={{ margin: '12px 0' }} />
                                            <Typography variant="body2">
                                                Total de Vendas: R$ {formatNumber(kpis.totalVendas)}
                                            </Typography>
                                            <Typography variant="body2">
                                                Pedidos nos últimos 30 dias: {kpis.pedidos30Dias}
                                            </Typography>
                                            <Typography variant="body2">
                                                Preço Médio: R$ {formatNumber(kpis.precoMedio)}
                                            </Typography>
                                            <Divider sx={{ margin: '12px 0' }} />
                                            <div style={{ height: '150px' }}>
                                                <Line data={salesData} options={options} />
                                            </div>
                                        </CardContent>
                                    </Card>
                                </MuiTooltip>
                            </Grid>
                        );
                    })}
                </Grid>
            </CardContent>
        </Card>
    );
};

export default TopProductsCard;
